
(function ($) {
    function BoxParallaxing(box) {

        var controller = new ScrollMagic.Controller();
        var id = box.id;

        var duration = $(window).height();
        var distance = 300;

        var elementbox = '#' + id + ' div';
        var tween = new TimelineMax()
            .to('#' + id + ' .box' , 1 ,{bottom: "+=" + distance});

        // build scene box-parallaxing
        var scene = new ScrollMagic.Scene({triggerElement: box, duration: duration, triggerHook: '1', offset: 300})
            .setTween(tween)
            //.addIndicators({name: "1 (duration: " + duration + " )"}) // add indicators (requires plugin)
            .addTo(controller);

        if (!$('body').hasClass('js') && controller.enabled()) {
            controller.enabled(false);
        } else if (!controller.enabled()) {
            controller.enabled(true);
        }

        $(window).on("resize", function(e) {
            if (!$('body').hasClass('js') && controller.enabled()) {
                $('.imagebox .box').css('bottom', '0')
                controller.enabled(false);
            } else if ($('body').hasClass('js') && !controller.enabled()) {
                controller.enabled(true);
            }
            controller.update(true);
        });
    }

    $.fn.BoxParallax = function () {
        $(this).each(function () {
            if (!('_BoxParallax' in this)) {
                this._BoxParallax = new BoxParallaxing(this);
            }
        });

        return this;
    }
})(jQuery);

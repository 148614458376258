
(function ($) {
    function BoxWriter(box) {

        var element = $(box).find('.box p'); // box to be animated

        var spans = $(element).find('span');
        var id = box.id;
        var position = $(window).scrollTop();

        var toreturn = '';
        var width = 0;
        var broken = "";
        var textlength = 0;
        var index = 0;

        spans.each(function(){
            if($(this).width() > width) {
                width = $(this).width();
            }

            var text = $(this).text();
            broken = text.split('');

            textlength = textlength + broken.length;

            toreturn += "<span class='line'>";

            for(var i = 0; i < broken.length; i++) {
                toreturn += "<span id='" + id + "_letter" + index + "'>" + broken[i] + "</span>"
                index++;
            }

            toreturn += "</span>";
        })


        $(element).html(toreturn);

        $(window).scroll( function() {
            position = $(window).scrollTop() + $(window).height();

            if(position > $(element).offset().top + 200){
                write(0, textlength, id);
            }
        });
    }

    function write(counter, end, id) {
        if(counter < end){
            setTimeout(function(){
                $("#" + id + "_letter" + counter).addClass('written');
                counter++;
                write(counter, end, id);
            }, 50);
        }
    }



    $.fn.Box = function () {

        $(this).each(function () {
            if (!('_BoxWriter' in this)) {
                this._BoxWriter = new BoxWriter(this);
            }
        });

        return this;
    }
})(jQuery);

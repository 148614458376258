
(function ($) {
    function ImageParallaxing(box) {
        var controller = new ScrollMagic.Controller();

        var id = box.id;

        var duration = $(window).height();

        new ScrollMagic.Scene({triggerElement: box, duration: duration, triggerHook: '1'})
            .setTween("#" + id + " > div", {bottom: "-10vh", ease: Linear.easeNone})
            .addTo(controller);

    }

    $.fn.ImageParallax = function () {
        $(this).each(function () {
            if (!('_ImageParallax' in this)) {
                this._ImageParallax = new ImageParallaxing(this);
            }
        });

        return this;
    }
})(jQuery);

$(document).ready(function() {
    var breakpoint = $('html').css('z-index');

    if(breakpoint >= 200) {
        $('body').addClass('js');
        var $boxparallax = $('.imagebox').BoxParallax();
        var $imageparallax = $('.full-image-wrapper').ImageParallax();
        var $box = $('.imagebox').Box();
    } else {
        $('body').removeClass('js');
    }
})
